import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import db from '../../firebase/firebase';
import { useTranslation } from 'react-i18next';

const WebUserTable = ({ users, cloverMID }) => {
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [newMerchantId, setNewMerchantId] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // Track error messages

    React.useEffect(() => {
        if (users && users.length > 0) {
            setFilteredUsers(users.filter(user => {
                if (Array.isArray(user.merchantId)) {
                    return user.merchantId.includes(cloverMID);
                }
                return user.merchantId === cloverMID;
            }));
        }
    }, [users, cloverMID]);

    const handleEditClick = user => {
        setSelectedUser(user);
        setNewMerchantId('');
        setErrorMessage(''); // Clear previous error messages
    };

    const handleInputChange = e => {
        setNewMerchantId(e.target.value);
    };

    const handleFormSubmit = async e => {
        e.preventDefault();
        if (selectedUser && newMerchantId) {
            if (
                Array.isArray(selectedUser.merchantId) &&
                selectedUser.merchantId.includes(newMerchantId)
            ) {
                setErrorMessage(t('MID_exists'));
                return;
            }
            if (
                !Array.isArray(selectedUser.merchantId) &&
                selectedUser.merchantId === newMerchantId
            ) {
                setErrorMessage('Merchant ID already exists.');
                return;
            }

            try {
                let updatedMerchantId;
                if (Array.isArray(selectedUser.merchantId)) {
                    updatedMerchantId = [...selectedUser.merchantId, newMerchantId];
                } else {
                    updatedMerchantId = [selectedUser.merchantId, newMerchantId];
                }

                const userRef = doc(db, 'Users', selectedUser.id); // Adjust collection path
                await updateDoc(userRef, { merchantId: updatedMerchantId });

                alert('Merchant ID updated successfully!');
                setSelectedUser(null); // Close the form
                setFilteredUsers(prev =>
                    prev.map(user =>
                        user.id === selectedUser.id
                            ? { ...user, merchantId: updatedMerchantId }
                            : user
                    )
                );
            } catch (error) {
                console.error('Error updating merchantId:', error);
                alert('Failed to update merchantId');
            }
        }
    };

    const { t } = useTranslation();

    return (
        <div className="table-container w-full">
            <div className="overflow-x-auto">
            <table className="w-full bg-white border border-zinc-200">
                <thead className="sticky top-0 bg-white">
                    <tr className="bg-white/opacity-0">
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('first_name')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('email')}</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">UID</th>
                        <th className="w-auto bg-slate-100 border-t border-zinc-200 px-3 py-4">{t('action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredUsers && filteredUsers.length > 0 ? (
                        filteredUsers.map(user => (
                            <tr key={user.id} className="border-b border-gray-300">
                                <td className="px-4 py-2 text-center">{user.firstname}</td>
                                <td className="px-4 py-2 text-center">{user.email}</td>
                                <td className="px-4 py-2 text-center">{user.uid}</td>
                                <td className="px-4 py-2 text-center">
                                    <button className="px-3 py-2  rounded-lg border-2 border-sky-900"  onClick={() => handleEditClick(user)}>
                                        <div className="px-1 justify-center items-center flex">
                                            <div className="text-sky-900 text-xl font-medium leading-7">+</div>
                                        </div>
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={4} className="px-4 py-2 text-center">{t('msg_no_users_available')}</td>
                        </tr>
                    )}
                </tbody>
            </table>
            </div>

            {/* Form for adding new merchantId */}
            {selectedUser && (
                <div className="mt-4 p-4 border border-gray-300 rounded bg-gray-50">
                    <h3 className="text-lg font-semibold">{t('add_merchant_id')}</h3>
                    <form onSubmit={handleFormSubmit}>
                        <div className="mb-4">
                            <label className="block text-sm font-medium">{t('name')}</label>
                            <input
                                type="text"
                                value={selectedUser.firstname}
                                readOnly
                                className="w-full p-2 border border-gray-300 rounded bg-gray-100"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium">{t('email')}</label>
                            <input
                                type="text"
                                value={selectedUser.email}
                                readOnly
                                className="w-full p-2 border border-gray-300 rounded bg-gray-100"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium">UID</label>
                            <input
                                type="text"
                                value={selectedUser.uid}
                                readOnly
                                className="w-full p-2 border border-gray-300 rounded bg-gray-100"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium">{t('new_merchant_id')}</label>
                            <input
                                type="text"
                                value={newMerchantId}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-300 rounded"
                                placeholder={t('enter_new_merchant_id')}
                            />
                        </div>
                        {errorMessage && (
                            <p className="text-red-500 mb-4">{errorMessage}</p>
                        )}
                        <div className="flex items-center gap-4">
                            {/* <button
                                type="submit"
                                className="px-4 py-2 bg-green-500 text-white rounded"
                            >
                                {t('save')}
                            </button> */}
                            <button
                                type="submit"
                                className="px-3 py-2  rounded-lg border-2 border-sky-900"  >
                                    <div className="px-1 justify-center items-center flex">
                                        <div className="text-sky-900 text-xl font-medium leading-7">{t('save')}</div>
                                    </div>
                            </button>
                            <button
                                type="button"
                                onClick={() => setSelectedUser(null)}
                                className="px-3 py-2  rounded-lg border-2 border-red-500"  >
                                    <div className="px-1 justify-center items-center flex">
                                        <div className="text-red-500 text-xl font-medium leading-7"> {t('cancel')}</div>
                                    </div>
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default WebUserTable;
